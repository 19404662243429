import {API_URL_PREFIX, CreateRoleDto, GetAllRolesParams, RoleDto, UpdateRoleDto} from '@accounts-api/util';
import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RolesApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getAllRoles(rawParams: GetAllRolesParams = {}): Observable<readonly RoleDto[]> {
    return this.http.get<null>(`${this.gatewayUrl}/${this.apiPrefix}/roles`, {params: rawParams});
  }

  create(body: CreateRoleDto): Observable<string> {
    return this.http.post<string>(`${this.gatewayUrl}/${this.apiPrefix}/roles`, body);
  }

  update(body: UpdateRoleDto, roleId: string) {
    return this.http.patch<string>(`${this.gatewayUrl}/${this.apiPrefix}/roles/${roleId}`, body);
  }

  deleteRole(roleId: string): Observable<null> {
    return this.http.delete<null>(`${this.gatewayUrl}/${this.apiPrefix}/roles/${roleId}`);
  }
}
