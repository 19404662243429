import {API_URL_PREFIX, UpdateUserDto} from '@accounts-api/util';
import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  updateUser(userId: string, data: UpdateUserDto): Observable<string> {
    return this.http.post<string>(`${this.gatewayUrl}/${this.apiPrefix}/user/${userId}`, data);
  }
}
